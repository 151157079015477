/* ---------- */
/* Site Wide  */
/* ---------- */
html, body {
  background-color: black;
  color: white;
}

body {
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

code { font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }
ul { list-style: disc; }

/* Header & Text */
header { padding-top: .75rem; }
h1 {
  font-family: 'Dancing Script', cursive;
	font-size: 3rem;
}
h2 {
  font-family: 'Sacramento', cursive;
  font-size: 3.5rem;
}
p { font-size: 1.5rem; line-height: 1.8rem; }
a { font-size: 1.15rem; line-height: 1.2rem; }

/* Height */
.vh-100 { height: 100vh; }
.overflowy-auto { overflow-y: auto; }

/* Margin */
.mb-1 { margin-bottom: 4px; }
.mt-1 { margin-top: 4px; }
.mx-1 { margin-left: 4px; margin-right: 4px; }
.mx-2 { margin-left: 8px; margin-right: 8px; }
.mx-3 { margin-left: 12px; margin-right: 12px; }
.mb-3 { margin-bottom: 12px; }
.mt-3 { margin-top: 12px; }
.my-3 { margin-top: 12px; margin-bottom: 12px; }
.mb-5 { margin-bottom: 32px; }
.mt-5 { margin-top: 32px; }
.mx-5 { margin-left: 32px; margin-right: 32px; }
.my-5 { margin-bottom: 32px; margin-top: 32px; }
.mx-auto { margin-left: auto; margin-right: auto; }

/* Flex */
.d-flex { display: flex; }
.align-items-center { align-items: center; }
.text-center { text-align: center; }
.justify-content-center { justify-content: center; }
.flex-column { flex-direction: column; }
.text-left { text-align: left; }

/* Links */
.nav-link { color: white; text-decoration: none; padding: 1rem; }
.nav-link.active { color: white; }
.nav-link:hover { text-decoration: underline; }
.page-link { color: lightblue; text-decoration: none; }

/* App Specific */

.splash-photo {
  max-width: 100%;
  width: 100%;
  max-height: 480px;
  object-fit: contain;
}

.splash-container {
  z-index: 1;
}

.container {
  display: grid;
  gap: 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.gallery {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(1, auto);
}

.gallery-item {
  margin: 1rem;
  width: 350px;
  height: 350px;
  align-self: center;
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

/* Modal */
.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
}

.modal-header, .modal-content {
  padding: 1rem;
}

.modal-content {
  margin: 0 auto; /* 15% from the top and centered */
  width: 80%; /* Could be more or less, depending on screen size */
}

.modal-image {
  height: 100%;
  object-fit: contain;
  max-height: 100vh;
}

.visually-hidden, .is-closed {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.close {
  position: fixed;
  top: 0;
  right: 0;
  background: transparent;
  border: 0;
  margin: 1rem;
  cursor: pointer;
  color: white;
  font-size: 2rem;
}

/* Media Queries */
@media only screen and (max-width: 600px) {
  header, main { margin: 0 2rem; }
}

@media (min-width: 900px) {
  .gallery {
    grid-template-columns: repeat(3, 1fr);
  }

  .gallery-item {
    margin: 1rem;
    width: 275px;
    height: 275px;
  }
}
